import React from "react"
import Calendar from "react-calendar"
import { Popover, Modal, OverlayTrigger } from "react-bootstrap"
const dateArray = [
  {
    dayOfMonth: 23,
    month: 5, //june
    year: 2020,
    title: "Pre-Lightschools Training - eduScrum",
    time: "7 AM - 3 PM CEST",
    background: "#005d41",
    color: "white",
  },
  {
    dayOfMonth: 25,
    month: 5,
    year: 2020,
    title: "Pre-Lightschools Training - eduScrum",
    time: " 7 AM - 3 PM CEST",
    background: "#005d41",
    color: "white",
  },
  {
    dayOfMonth: 30,
    month: 5,
    year: 2020,
    title: "Pre-Lightschools Training",
    time: " 7 AM - 3 PM CEST",
    background: "#005d41",
    color: "white",
  },
  {
    dayOfMonth: 1,
    month: 6, //july
    year: 2020,
    title: "Pre-Lightschools Training",
    time: " 7 AM - 3 PM CEST",
    background: "#005d41",
    color: "white",
  },
  {
    dayOfMonth: 2,
    month: 6, //july
    year: 2020,
    title: "Pre-Lightschools Training",
    time: " 7 AM - 3 PM CEST",
    background: "#005d41",
    color: "white",
  },
  {
    dayOfMonth: 14,
    month: 6,
    year: 2020,
    title: "Pre-Lightschools Training - eduScrum",
    time: " 7 AM - 3 PM CEST",
    background: "#005d41",
    color: "white",
  },
  {
    dayOfMonth: 15,
    month: 6,
    year: 2020,
    title: "Pre-Lightschools Training - eduScrum",
    time: " 7 AM - 3 PM CEST",
    background: "#005d41",
    color: "white",
  },
  {
    dayOfMonth: 21,
    month: 6,
    year: 2020,
    title: "Pre-Lightschools Training",
    time: " 7 AM - 3 PM CEST",
    background: "#005d41",
    color: "white",
  },
  {
    dayOfMonth: 22,
    month: 6,
    year: 2020,
    title: "Pre-Lightschools Training",
    time: " 7 AM - 3 PM CEST",
    background: "#005d41",
    color: "white",
  },
  {
    dayOfMonth: 23,
    month: 6,
    year: 2020,
    title: "Pre-Lightschools Training",
    time: " 7 AM - 3 PM CEST",
    background: "#005d41",
    color: "white",
  },
  {
    dayOfMonth: 4,
    month: 7, //august
    year: 2020,
    title: "Pre-Lightschools Training - eduScrum",
    time: " 7 AM - 3 PM CEST",
    background: "#005d41",
    color: "white",
  },
  {
    dayOfMonth: 5,
    month: 7, //august
    year: 2020,
    title: "Pre-Lightschools Training - eduScrum",
    time: " 7 AM - 3 PM CEST",
    background: "#005d41",
    color: "white",
  },
  {
    dayOfMonth: 11,
    month: 7, //august
    year: 2020,
    title: "Pre-Lightschools Training",
    time: " 7 AM - 3 PM CEST",
    background: "#005d41",
    color: "white",
  },
  {
    dayOfMonth: 12,
    month: 7, //august
    year: 2020,
    title: "Pre-Lightschools Training",
    time: " 7 AM - 3 PM CEST",
    background: "#005d41",
    color: "white",
  },
  {
    dayOfMonth: 13,
    month: 7, //august
    year: 2020,
    title: "Pre-Lightschools Training",
    time: " 7 AM - 3 PM CEST",
    background: "#005d41",
    color: "white",
  },
]
const CalendarDateModal = ({ calendarDates, modalShow, setModalShow }) => {
  const RenderCalContent = ({ date, view }) => {
    const dayOfMonth = date.getDate() //1-31
    const month = date.getMonth() //0-11
    const year = date.getFullYear()
    const matchedDate = calendarDates.find(
      // subtract 1 from day of month because user enters 1-12
      calDate =>
        Number(calDate.month) - 1 === month &&
        Number(calDate.day) === dayOfMonth &&
        Number(calDate.year) === year
    )
    console.log(matchedDate)
    if (!!matchedDate) {
      return (
        <OverlayTrigger
          key={"top"}
          placement={"top"}
          overlay={
            <Popover id="popover-basic">
              <Popover.Title as="h3">{matchedDate.title}</Popover.Title>
              <Popover.Content>{matchedDate.time}</Popover.Content>
            </Popover>
          }
        >
          <div
            style={{
              background: matchedDate.backgroundColor,
              color: matchedDate.textColor,
              lineHeight: "260%",
            }}
          >
            {dayOfMonth}
          </div>
        </OverlayTrigger>
      )
    } else {
      return null
    }
  }
  return modalShow ? (
    <Calendar
      className="w-100 my-5"
      tileContent={({ date, view }) => (
        <RenderCalContent date={date} view={view} />
      )}
    />
  ) : null

  // <Modal
  //   size="lg"
  //   centered
  //   onHide={() => setModalShow(false)}
  //   show={modalShow}
  // >
  //   <Modal.Body>
  //     <Calendar
  //       onClickDay={e => console.log(e)}
  //       style={{ width: "100%" }}
  //       hover={e => console.log(e)}
  //       tileContent={({ date, view }) => (
  //         <RenderCalContent date={date} view={view} />
  //       )}
  //     />
  //   </Modal.Body>
  // </Modal>
}

export default CalendarDateModal
