import React, { useState } from "react"
import { Link } from "gatsby"
import ReactMarkdown from "react-markdown/with-html"
import {
  Row,
  Col,
  Button,
  Accordion,
  Card,
  InputGroup,
  FormControl,
  Form,
  Popover,
  Modal,
  OverlayTrigger,
} from "react-bootstrap"
import CalendarModal from "../components/CalendarDateModal"
import Layout from "../components/layout"
import SEO from "../components/seo"

export const AcademyPageTemplate = ({
  title,
  calendarDates,
  section1,
  section2,
  section3,
  sectionBottom,
}) => {
  const { season1, season2, season3, season4 } = section3
  const [modalShow, setModalShow] = useState(false)
  const Section_PreLightSchoolsTraining = () => (
    <Card>
      <Accordion.Toggle as={Card.Header} eventKey="0">
        Pre-Lightschools Training
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="0">
        <Card.Body>
          <Row className="d-flex flex-row justify-content-center">
            <Col
              xs={10}
              lg={8}
              className="d-flex flex-column justify-content-center"
            >
              <h3>HIGHLIGHTS</h3>
              <p>
                Essentials training
                <br />
                Get a sense of your role & journey within Lightschools <br />
                Recruitment process
              </p>
              <h3>IDEAL FOR</h3>
              <p>
                - People who want to find out how they can use their qualities,
                passions and ambitions within Lightschools
                <br />
                - People who want to find out if becoming a lightschool teacher
                or connector suits them
                <br />- People who want to prepare for their Lightschools
                learning journey
              </p>
              <h3>MODULE DETAILS</h3>
              <p>
                Do you consider developing a project in line with the
                Lightschools vision in your local environment? Do you want to
                explore how you could use your qualities, passions and ambitions
                together with like-minded people according to Lightschools
                principles? In the Pre-Lightschools Training we explore what
                ‘role’ within Lightschools suits you. Maybe you are a true
                lightschool teacher or connector! The Pre-Lightschools Training
                can be attended online. It will support your integrity,
                commitment and knowledge of Lightschools. You will experience
                what it is like to be a lightschool teacher and a lightschool
                connector and how Lightschools works, both locally and globally.
                You learn to work in the online structures and with Lightschools
                Common Principles. If you are not yet familiar with eduScrum
                then a 2-day eduScrum training is part of the Pre-Lightschools
                Training. By experiencing Lightschools and the different roles,
                you and we as teachers get a picture of what actually suits you.
                At the end of the training we will discuss this together.
              </p>
              <h3>REQUIREMENTS</h3>
              <p>
                - A clear personal WHY. Why do you want to develop a lightschool
                project or build up a local lightschool? Why do you want to
                become a lightschool teacher and/or connector?
                <br /> - Attended at least three Common Principle Webinars
                <br /> - Openness and willingness to act within the structures &
                according to Ligthschools Common Principles.
              </p>
              <h3>DATES</h3>
              <p>
                Online 3 to 5-days Essentials training between July-September.
              </p>
              <Button
                className="buttonStyle align-self-center"
                onClick={() => setModalShow(!modalShow)}
              >
                VIEW UPCOMING TRAINING DATES
              </Button>
              <CalendarModal
                modalShow={modalShow}
                setModalShow={setModalShow}
                calendarDates={calendarDates}
              />
              <h1>Prices*</h1>
              <p>
                - 5-day online Pre-Lightschools Training with eduScrum: 650,-
                euro
                <br />- 3-day online Pre-Lightschools Training without eduScrum:
                400,- euro
                <br />
                <small>*Note: these are pilot prices</small>
              </p>
              <Link to="/enrollment" className="align-self-center">
                <Button size="lg" className=" buttonStyle my-3">
                  ENROLL NOW
                </Button>
              </Link>
            </Col>
          </Row>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  )

  return (
    <Layout>
      <SEO title="Academy" />

      <Row className="mx-0" style={{ marginTop: "-73px" }}>
        <Col className="px-0">
          <div className="w-100 d-flex justify-content-center align-items-center academy-landing-image">
            <div className="overlay"></div>
            <div className="sectionTitleWrap text-center">
              <h1 className="sectionTitle">{title}</h1>
              <Link to="/academy#modules">
                <Button className="buttonStyle ">View Modules</Button>
              </Link>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="d-flex flex-column align-items-center academy-section2">
        <Col xs={10} lg={8}>
          <div className="wrapper">
            <ReactMarkdown source={section1} linkTarget="_blank" />
          </div>
        </Col>
      </Row>

      <Row className="academy-section-roles">
        <div className="overlay-top"></div>
        <Col className="px-0">
          <div className="w-100 d-flex justify-content-center align-items-center bg-image">
            <div className="overlay"></div>
            <div className="sectionTitleWrapper">
              <h1 className="sectionTitle">{section2.title}</h1>
            </div>
          </div>
        </Col>
        <div className="overlay-bottom"></div>
      </Row>
      <Row className="py-5 d-flex flex-column mx-0 academy-section-lower align-items-center">
        <Col xs={10} lg={8}>
          <div style={{ position: "relative", zIndex: 2 }}>
            <ReactMarkdown
              source={section2.contentTop}
              escapeHtml={false}
              linkTarget="_blank"
            />
          </div>
          <div style={{ position: "relative", zIndex: 1 }}>
            <span className="bg-text">Shu Ha Ri</span>
          </div>
          {/* <p>
            All modules are <span>1-week retreats*</span> with their own goals
            in terms of personal and professional development. Our teachers are
            committed to provide open, holistic and inspiring education. Within
            the modules there are different themes. Every theme builds on the
            previous. Within every theme there are two levels that create a
            balance between inner work and outer work:
          </p>
          <p className="py-5">
            Lightschools’ aim is to build a new education & innovation ecosystem{" "}
            <strong>together</strong>. To make that happen we empower local
            lightschools and the global network with:
          </p>
          <div style={{ position: "relative", zIndex: 1 }}>
            <span className="bg-text">Shu Ha Ri</span>
            <p>
              <h1 style={{ display: "inline", color: "#fdebea" }}>SHU</h1> what
              does this theme mean for me?
            </p>
            <p>
              <h1 style={{ display: "inline" }}>HA</h1> what does this theme
              mean for my acting as a teacher or connector, guiding others?
            </p>
            <p>
              <h1 style={{ display: "inline" }}>RI</h1> is the third level and
              not part of the modules
            </p>
          </div>
          <p className="pt-3">
            In the third ‘Ri’ level the teacher as well as the others, flourish
            personally and collectively from a balance in the different themes.
            This will be a natural result with practice. Gaining experience in
            practice is therefore a requirement from the first moment onwards.
          </p>
          <p className="italics">
            *The Pre-Lightschools Training is not a 1-week retreat. It is an
            Essentials training that can be attended both online and offline
          </p> */}
        </Col>
      </Row>
      <Row className="academy-section-retreat d-flex flex-column justify-content-center align-items-center pt-5 pb-3">
        <Col xs={10} lg={8}>
          <div class="mt-5">
            <ReactMarkdown
              source={section2.contentBottom}
              escapeHtml={false}
              linkTarget="_blank"
            />
          </div>
        </Col>
      </Row>
      <Row className="academy-section-modules">
        <div className="overlay-top"></div>
        <Col className="px-0">
          <div className="w-100 d-flex justify-content-center align-items-center bg-image">
            <div className="overlay"></div>
            <div className="sectionTitleWrapper">
              <h1 className="sectionTitle" id="modules">
                {section3.title}
              </h1>
              <h3>{section3.subTitle}</h3>
              <Button
                className="buttonStyle align-self-center mt-5"
                onClick={() => setModalShow(!modalShow)}
              >
                VIEW UPCOMING TRAINING DATES
              </Button>
              <CalendarModal
                modalShow={modalShow}
                setModalShow={setModalShow}
                calendarDates={calendarDates}
              />
            </div>
          </div>
        </Col>
        <div className="overlay-bottom"></div>
      </Row>
      <Row className="d-flex flex-column justify-content-center align-items-center academy-section-summer">
        <Col className="my-3">
          <h1>{season1.title}</h1>
          <h5>{season1.subTitle}</h5>
        </Col>
        <Col>
          <Accordion className="accordion">
            {/* <Section_PreLightSchoolsTraining /> */}
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="0">
                {season1.expandable1.title}
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <Row className="d-flex flex-row justify-content-center">
                    <Col
                      xs={10}
                      lg={8}
                      className="d-flex flex-column justify-content-center"
                    >
                      <ReactMarkdown
                        source={season1.expandable1.content}
                        escapeHtml={false}
                        linkTarget="_blank"
                      />
                      {season1.expandable1.prices !== "hidden" ? (
                        <>
                          <Button
                            className="buttonStyle align-self-center"
                            onClick={() => setModalShow(!modalShow)}
                          >
                            VIEW UPCOMING TRAINING DATES
                          </Button>
                          <CalendarModal
                            modalShow={modalShow}
                            setModalShow={setModalShow}
                            calendarDates={calendarDates}
                          />
                          <ReactMarkdown
                            source={season1.expandable1.prices}
                            escapeHtml={false}
                            linkTarget="_blank"
                          />
                          <Link to="/enrollment" className="align-self-center">
                            <Button size="lg" className=" buttonStyle my-3">
                              ENROLL NOW
                            </Button>
                          </Link>
                        </>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
        <Col>
          <Accordion className="accordion">
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="1">
                {season1.expandable2.title}
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <Row className="d-flex flex-row justify-content-center">
                    <Col xs={10} lg={8}>
                      <ReactMarkdown
                        source={season1.expandable2.content}
                        escapeHtml={false}
                        linkTarget="_blank"
                      />
                      {season1.expandable2.prices !== "hidden" ? (
                        <>
                          <Button
                            className="buttonStyle align-self-center"
                            onClick={() => setModalShow(!modalShow)}
                          >
                            VIEW UPCOMING TRAINING DATES
                          </Button>
                          <CalendarModal
                            modalShow={modalShow}
                            setModalShow={setModalShow}
                            calendarDates={calendarDates}
                          />
                          <ReactMarkdown
                            source={season1.expandable2.prices}
                            escapeHtml={false}
                            linkTarget="_blank"
                          />
                          <Link to="/enrollment" className="align-self-center">
                            <Button size="lg" className=" buttonStyle my-3">
                              ENROLL NOW
                            </Button>
                          </Link>
                        </>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
        <Col>
          <Accordion className="accordion">
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="2">
                {season1.expandable3.title}
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <Row className="d-flex flex-row justify-content-center">
                    <Col xs={10} lg={8}>
                      <ReactMarkdown
                        source={season1.expandable3.content}
                        escapeHtml={false}
                        linkTarget="_blank"
                      />
                      {season1.expandable3.prices !== "hidden" ? (
                        <>
                          <Button
                            className="buttonStyle align-self-center"
                            onClick={() => setModalShow(!modalShow)}
                          >
                            VIEW UPCOMING TRAINING DATES
                          </Button>
                          <CalendarModal
                            modalShow={modalShow}
                            setModalShow={setModalShow}
                            calendarDates={calendarDates}
                          />
                          <ReactMarkdown
                            source={season1.expandable3.prices}
                            escapeHtml={false}
                            linkTarget="_blank"
                          />
                          <Link to="/enrollment" className="align-self-center">
                            <Button size="lg" className=" buttonStyle my-3">
                              ENROLL NOW
                            </Button>
                          </Link>
                        </>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
        <Col className="my-3">
          <h1>{season2.title}</h1>
          <h5>{season2.subTitle}</h5>
        </Col>
        <Col>
          <Accordion className="accordion">
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="3">
                {season2.expandable1.title}
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <Row className="d-flex flex-row justify-content-center">
                    <Col xs={10} lg={8}>
                      <ReactMarkdown
                        source={season2.expandable1.content}
                        escapeHtml={false}
                        linkTarget="_blank"
                      />
                      {season2.expandable1.prices !== "hidden" ? (
                        <>
                          <Button
                            className="buttonStyle align-self-center"
                            onClick={() => setModalShow(!modalShow)}
                          >
                            VIEW UPCOMING TRAINING DATES
                          </Button>
                          <CalendarModal
                            modalShow={modalShow}
                            setModalShow={setModalShow}
                            calendarDates={calendarDates}
                          />
                          <ReactMarkdown
                            source={season2.expandable1.prices}
                            escapeHtml={false}
                            linkTarget="_blank"
                          />
                          <Link to="/enrollment" className="align-self-center">
                            <Button size="lg" className=" buttonStyle my-3">
                              ENROLL NOW
                            </Button>
                          </Link>
                        </>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
        <Col>
          <Accordion className="accordion">
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="4">
                {season2.expandable2.title}
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="4">
                <Card.Body>
                  <Row className="d-flex flex-row justify-content-center">
                    <Col xs={10} lg={8}>
                      <ReactMarkdown
                        source={season2.expandable2.content}
                        escapeHtml={false}
                        linkTarget="_blank"
                      />
                      {season2.expandable2.prices !== "hidden" ? (
                        <>
                          <Button
                            className="buttonStyle align-self-center"
                            onClick={() => setModalShow(!modalShow)}
                          >
                            VIEW UPCOMING TRAINING DATES
                          </Button>
                          <CalendarModal
                            modalShow={modalShow}
                            setModalShow={setModalShow}
                            calendarDates={calendarDates}
                          />
                          <ReactMarkdown
                            source={season2.expandable2.prices}
                            escapeHtml={false}
                            linkTarget="_blank"
                          />
                          <Link to="/enrollment" className="align-self-center">
                            <Button size="lg" className=" buttonStyle my-3">
                              ENROLL NOW
                            </Button>
                          </Link>
                        </>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
        <Col>
          <Accordion className="accordion">
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="5">
                {season2.expandable3.title}
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="5">
                <Card.Body>
                  <Row className="d-flex flex-row justify-content-center">
                    <Col xs={10} lg={8}>
                      <ReactMarkdown
                        source={season2.expandable3.content}
                        escapeHtml={false}
                        linkTarget="_blank"
                      />
                      {season2.expandable3.prices !== "hidden" ? (
                        <>
                          <Button
                            className="buttonStyle align-self-center"
                            onClick={() => setModalShow(!modalShow)}
                          >
                            VIEW UPCOMING TRAINING DATES
                          </Button>
                          <CalendarModal
                            modalShow={modalShow}
                            setModalShow={setModalShow}
                            calendarDates={calendarDates}
                          />
                          <ReactMarkdown
                            source={season2.expandable3.prices}
                            escapeHtml={false}
                            linkTarget="_blank"
                          />
                          <Link to="/enrollment" className="align-self-center">
                            <Button size="lg" className=" buttonStyle my-3">
                              ENROLL NOW
                            </Button>
                          </Link>
                        </>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
        <Col className="my-3">
          <h1>{season3.title}</h1>
          <h5>{season3.subTitle}</h5>
        </Col>
        <Col>
          <Accordion className="accordion">
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="6">
                {season3.expandable1.title}
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="6">
                <Card.Body>
                  <Row className="d-flex flex-row justify-content-center">
                    <Col
                      xs={10}
                      lg={8}
                      className="d-flex flex-column justify-content-center"
                    >
                      <ReactMarkdown
                        source={season3.expandable1.content}
                        escapeHtml={false}
                        linkTarget="_blank"
                      />
                      {season3.expandable1.prices !== "hidden" ? (
                        <>
                          <Button
                            className="buttonStyle align-self-center"
                            onClick={() => setModalShow(!modalShow)}
                          >
                            VIEW UPCOMING TRAINING DATES
                          </Button>
                          <CalendarModal
                            modalShow={modalShow}
                            setModalShow={setModalShow}
                            calendarDates={calendarDates}
                          />
                          <ReactMarkdown
                            source={season3.expandable1.prices}
                            escapeHtml={false}
                            linkTarget="_blank"
                          />
                          <Link to="/enrollment" className="align-self-center">
                            <Button size="lg" className=" buttonStyle my-3">
                              ENROLL NOW
                            </Button>
                          </Link>
                        </>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
        <Col>
          <Accordion className="accordion">
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="7">
                {season3.expandable2.title}
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="7">
                <Card.Body>
                  <Row className="d-flex flex-row justify-content-center">
                    <Col xs={10} lg={8}>
                      <ReactMarkdown
                        source={season3.expandable2.content}
                        escapeHtml={false}
                        linkTarget="_blank"
                      />
                      {season3.expandable2.prices !== "hidden" ? (
                        <>
                          <Button
                            className="buttonStyle align-self-center"
                            onClick={() => setModalShow(!modalShow)}
                          >
                            VIEW UPCOMING TRAINING DATES
                          </Button>
                          <CalendarModal
                            modalShow={modalShow}
                            setModalShow={setModalShow}
                            calendarDates={calendarDates}
                          />
                          <ReactMarkdown
                            source={season3.expandable2.prices}
                            escapeHtml={false}
                            linkTarget="_blank"
                          />
                          <Link to="/enrollment" className="align-self-center">
                            <Button size="lg" className=" buttonStyle my-3">
                              ENROLL NOW
                            </Button>
                          </Link>
                        </>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
        <Col>
          <Accordion className="accordion">
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="8">
                {season3.expandable3.title}
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="8">
                <Card.Body>
                  <Row className="d-flex flex-row justify-content-center">
                    <Col xs={10} lg={8}>
                      <ReactMarkdown
                        source={season3.expandable3.content}
                        escapeHtml={false}
                        linkTarget="_blank"
                      />
                      {season3.expandable3.prices !== "hidden" ? (
                        <>
                          <Button
                            className="buttonStyle align-self-center"
                            onClick={() => setModalShow(!modalShow)}
                          >
                            VIEW UPCOMING TRAINING DATES
                          </Button>
                          <CalendarModal
                            modalShow={modalShow}
                            setModalShow={setModalShow}
                            calendarDates={calendarDates}
                          />
                          <ReactMarkdown
                            source={season3.expandable3.prices}
                            escapeHtml={false}
                            linkTarget="_blank"
                          />
                          <Link to="/enrollment" className="align-self-center">
                            <Button size="lg" className=" buttonStyle my-3">
                              ENROLL NOW
                            </Button>
                          </Link>
                        </>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
        <Col className="my-3">
          <h1>{season4.title}</h1>
          <h5>{season4.subTitle}</h5>
        </Col>
        <Col>
          <Accordion className="accordion">
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="9">
                {season4.expandable1.title}
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="9">
                <Card.Body>
                  <Row className="d-flex flex-row justify-content-center">
                    <Col xs={10} lg={8}>
                      <ReactMarkdown
                        source={season4.expandable1.content}
                        escapeHtml={false}
                        linkTarget="_blank"
                      />
                      {season4.expandable1.prices !== "hidden" ? (
                        <>
                          <Button
                            className="buttonStyle align-self-center"
                            onClick={() => setModalShow(!modalShow)}
                          >
                            VIEW UPCOMING TRAINING DATES
                          </Button>
                          <CalendarModal
                            modalShow={modalShow}
                            setModalShow={setModalShow}
                            calendarDates={calendarDates}
                          />
                          <ReactMarkdown
                            source={season4.expandable1.prices}
                            escapeHtml={false}
                            linkTarget="_blank"
                          />
                          <Link to="/enrollment" className="align-self-center">
                            <Button size="lg" className=" buttonStyle my-3">
                              ENROLL NOW
                            </Button>
                          </Link>
                        </>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
        <Col>
          <Accordion className="accordion">
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="10">
                {season4.expandable2.title}
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="10">
                <Card.Body>
                  <Row className="d-flex flex-row justify-content-center">
                    <Col xs={10} lg={8}>
                      <ReactMarkdown
                        source={season4.expandable2.content}
                        escapeHtml={false}
                        linkTarget="_blank"
                      />
                      {season4.expandable2.prices !== "hidden" ? (
                        <>
                          <Button
                            className="buttonStyle align-self-center"
                            onClick={() => setModalShow(!modalShow)}
                          >
                            VIEW UPCOMING TRAINING DATES
                          </Button>
                          <CalendarModal
                            modalShow={modalShow}
                            setModalShow={setModalShow}
                            calendarDates={calendarDates}
                          />
                          <ReactMarkdown
                            source={season4.expandable2.prices}
                            escapeHtml={false}
                            linkTarget="_blank"
                          />
                          <Link to="/enrollment" className="align-self-center">
                            <Button size="lg" className=" buttonStyle my-3">
                              ENROLL NOW
                            </Button>
                          </Link>
                        </>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
        <Col>
          <Accordion className="accordion">
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="11">
                {season4.expandable3.title}
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="11">
                <Card.Body>
                  <Row className="d-flex flex-row justify-content-center">
                    <Col xs={10} lg={8}>
                      <ReactMarkdown
                        source={season4.expandable3.content}
                        escapeHtml={false}
                        linkTarget="_blank"
                      />
                      {season4.expandable3.prices !== "hidden" ? (
                        <>
                          <Button
                            className="buttonStyle align-self-center"
                            onClick={() => setModalShow(!modalShow)}
                          >
                            VIEW UPCOMING TRAINING DATES
                          </Button>
                          <CalendarModal
                            modalShow={modalShow}
                            setModalShow={setModalShow}
                            calendarDates={calendarDates}
                          />
                          <ReactMarkdown
                            source={season4.expandable3.prices}
                            escapeHtml={false}
                            linkTarget="_blank"
                          />
                          <Link to="/enrollment" className="align-self-center">
                            <Button size="lg" className=" buttonStyle my-3">
                              ENROLL NOW
                            </Button>
                          </Link>
                        </>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
      </Row>
      <Row className="academy-section-teachers d-flex flex-column justify-content-center align-items-center pt-5 pb-3">
        <Col xs={10} lg={8} className="mt-3">
          <h1 className="mt-5">ACADEMY TEACHERS</h1>
          <p>
            We as the people from the heart of Lightschools are delighted to
            support you in your learning journey. We surround ourselves with
            profoundly human, joyous, caring and attentive people that support
            us and you during the retreats.
          </p>
          <p>
            Any questions? Mail to{" "}
            <a
              href="mailto:academy@lightschools.org"
              style={{ color: "#fab69fff" }}
            >
              academy@lightschools.org
            </a>
          </p>
        </Col>
      </Row>
      <Row className="academy-section-news d-flex flex-row justify-content-center align-items-center">
        <div className="overlay"></div>
        <Col xs={11} md={8}>
          <div className="w-100 d-flex flex-column justify-content-center align-items-center">
            <div className="sectionTitleWrapper">
              <h1 className="sectionTitle">Newsletter</h1>
              <p>
                Stay up-to-date about Lightschools’ events & receive inspiration
                with our newsletter, which appears 13 times a year.
              </p>
            </div>
          </div>
          <Form
            className="mt-5"
            action="https://t91884b07.emailsys1a.net/122/3739/5adaf24e63/subscribe/form.html"
            method="post"
            class="validate"
            target="_blank"
            novalidate
          >
            <Row>
              <Col xs={6}>
                <div
                  style={{
                    position: "absolute",
                    zIndex: "-100",
                    left: "-6000px",
                  }}
                  aria-hidden="true"
                >
                  <label for="rm_email">email: </label>
                  <input
                    type="text"
                    class="form_field"
                    name="rm_email"
                    id="rm_email"
                    value=""
                    tabindex="-1"
                  />
                  <label for="rm_comment">Comment: </label>
                  <textarea
                    name="rm_comment"
                    tabindex="-1"
                    id="rm_comment"
                  ></textarea>
                </div>
                <input
                  type="hidden"
                  name="url_ok"
                  value="https://lightschools.org/success"
                />
                <Form.Control
                  size="lg"
                  placeholder="First name"
                  name="first_name"
                />
              </Col>
              <Col xs={6}>
                <Form.Control
                  size="lg"
                  placeholder="Last name"
                  name="last_name"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <InputGroup className="mt-3">
                  <FormControl
                    size="lg"
                    placeholder="enter your email address"
                    aria-label="enter your email address"
                    aria-describedby="email address"
                    name="email"
                  />
                  <InputGroup.Append>
                    <Button type="submit">SIGN UP</Button>
                  </InputGroup.Append>
                </InputGroup>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col xs={8} className="align-self-center"></Col>
      </Row>
    </Layout>
  )
}

const AcademyPage = props => {
  const { frontmatter } = props.data.AcademyPageTemplate
  console.log(frontmatter)
  return (
    <AcademyPageTemplate
      title={frontmatter.title}
      calendarDates={frontmatter.calendarDates}
      section1={frontmatter.section1}
      section2={frontmatter.section2}
      section3={frontmatter.section3}
      sectionBottom={frontmatter.sectionBottom}
    />
  )
}

export default AcademyPage

export const pageQuery = graphql`
  query($id: String!) {
    AcademyPageTemplate: markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        calendarDates {
          title
          day
          month
          year
          time
          backgroundColor
          textColor
        }
        section1
        section2 {
          title
          contentTop
          contentBottom
        }
        section3 {
          title
          subTitle
          season1 {
            title
            subTitle
            expandable1 {
              title
              content
              prices
            }
            expandable2 {
              title
              content
              prices
            }
            expandable3 {
              title
              content
              prices
            }
          }
          season2 {
            title
            subTitle
            expandable1 {
              title
              content
              prices
            }
            expandable2 {
              title
              content
              prices
            }
            expandable3 {
              title
              content
              prices
            }
          }
          season3 {
            title
            subTitle
            expandable1 {
              title
              content
              prices
            }
            expandable2 {
              title
              content
              prices
            }
            expandable3 {
              title
              content
              prices
            }
          }
          season4 {
            title
            subTitle
            expandable1 {
              title
              content
              prices
            }
            expandable2 {
              title
              content
              prices
            }
            expandable3 {
              title
              content
              prices
            }
          }
        }
        sectionBottom
      }
    }
  }
`
